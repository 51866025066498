import { useEffect, useState } from "react"
import Link from 'next/link'
import { useRouter } from "next/router";
import { getCsrfToken, useSession, signIn, signOut, getSession } from "next-auth/react"
import { InputCustom, LoginLayouts, StorageApi } from '../components/MainCode/MainImport'
import { message, Spin } from 'antd'
import Image from "next/image";
import { nanoid } from "nanoid";

export default function Login(props) {
  const router = useRouter();
  const [ok, setOk] = useState(false)
  const [cfr, setCfr] = useState("");
  const [loading, setLoading] = useState(true);
  const [login, setLoginoff] = useState(false);
  const [username, setUsername] = useState('');
  const [randomCode, setRandomCode] = useState('');
  const [password, setPassword] = useState('');
  const [captcha, setCaptha] = useState('');
  const [pwdshow, setpwdshow] = useState(false);
  const disabled_style = password == '' || username == '' ? `bg-indigo-300 hover:bg-indigo-400` : `bg-indigo-600 hover:bg-indigo-700`;

  useEffect(e => {
    cekSesi()
    // cfrToken()
    // redirect()
  }, [loading])

  const cfrToken = async () => {
    setRandomCode(nanoid(5))
    const c = await getCsrfToken();
    setCfr(c)
  }

  const cekSesi = async () => {
    let sesi = await getSession();
    if (sesi) {
      Promise.resolve(StorageApi.getData(`sso_check?kode_login=${sesi.token_login}`))
        .then(value => {
          checksessionDatabase(1)
        })
        .catch(error => {
          cfrToken()
          setLoading(false)
        })
    } else {
      cfrToken()
      setLoading(false)
    }
  }


  const Login = async (e) => {
    e.preventDefault();
    setLoginoff(true)
    setOk(false)
    if (randomCode == captcha) {
      const res = await signIn('credentials', {
        redirect: false,
        username: username,
        password: password,
        callbackUrl: `${window.location.origin}`,
      });
      if (res?.status == 401) {
        message.error(JSON.parse(res?.error).errors)
        setOk(false)
        setLoginoff(false)
      } else {
        let sess = await getSession();
        message.success("Selamat, anda berhasil login");
        redirect();
        setOk(true);
        setLoginoff(false);
      }
    }else{
      message.info('Pastikan kode captcha sesuai')
        setOk(true)
        setLoginoff(false)
    }
  }

  
  async function redirect(){
    let sess = await getSession();
    if (sess) {
      const checkLogin = {
        kode_login : sess.token_login,
      }
      Promise.resolve(StorageApi.addData('login_sso',checkLogin))
      .then(value=>{
        checksessionDatabase(1)
      }).catch(error=>{
        checksessionDatabase(0)
      })
      } else {
      console.log('Tidak ada session')
    }
  }

  const checksessionDatabase = async(status) =>{
    let sess = await getSession()
    if (status == 1) {
      let redirectUrl = ""
      const second_bearer = await StorageApi.Refreshtoken(sess.bearer)
      if (sess.redirect.includes("http")) {
        if (sess.kode_modul == "SM_SATU_PETA") {
          redirectUrl = `${sess.redirect}?k=${sess.token_login}&b=${sess.bearer}`;
        }else{
          redirectUrl = `${sess.redirect}?token=${sess.token_login}&access=${second_bearer.data.data.second_bearer}`;
        }
      }else{
        if (sess.kode_unit.includes('STAKEHOLDER')) {
          const link_duplicat = `/embed-file/stakeholder${sess.kode_sub_unit ? '/'+sess.kode_sub_unit.replace(/SM_SOLO_STAKEHOLDER_|SM_STAKEHOLDER_|_ITDC|_BEAT|_ADMIN/g,'').toLowerCase() : ''}`
          redirectUrl = (sess.kode_sub_unit.includes('WWF_BEAT') || sess.kode_sub_unit.includes('WWF_ITDC')) ? `${link_duplicat}/${sess.kode_sub_unit.replace(/SM_STAKEHOLDER_WWF_/g,'').toLowerCase()}` : `${link_duplicat}/landing-page`
        }else{
          redirectUrl = sess.verify ? '/embed-file/embed-peta' : sess.redirect
        }
      }
      router.push(redirectUrl)
    }else{
        await signOut({
          redirect: false
        });
    }
  }
 
  if (loading) {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
        <Spin />
      </div>
    );
  }

  return (
    <>
      <LoginLayouts title={'LOGIN'} subtitle={'Login to your account'}>
        <div className="grid mt-4">
          <div className="form-group mb-2 items-center">
            <div className="w-1/2">
              <label htmlFor="" className="mr-3 text-white">Username</label>
            </div>
            <InputCustom type="text" name="value" placeholder={"Username"} value={username} onChange={(e) => setUsername(e.target.value)} />
          </div>
          <div className="form-group mb-2 items-center">
            <div className="w-1/2">
              <label htmlFor="" className="mr-3 text-white">Password</label>
            </div>
            <div className="flex">
              <InputCustom type={pwdshow ? 'text' : 'password'} name="value" placeholder={"Password"} value={password} onChange={(e) => setPassword(e.target.value)} />
              <button onClick={() => setpwdshow(!pwdshow)} className='ml-1 border hover:bg-[#E6EAF0] bg-white px-2 rounded-md'><Image src={pwdshow ? "/icons/eye-unshow.svg" : "/icons/eye-show.svg"} alt="" width={30} height={30} /></button>
            </div>
          </div>
          <div className="form-group mb-2 items-center">
            <div className="w-1/2">
              <label htmlFor="" className="mr-3 text-white">ReCaptcha</label>
            </div>
            <div className="flex">
              <div className="mr-3 bg-white items-center text-center justify-center px-2 rounded-md w-28">
                <p className="items-center justify-center !mt-1 -mb-5 text-center text-lg font-semibold italic cursor-not-allowed select-none">
                  {randomCode}
                </p>
              </div>
              <InputCustom type='text' name="value" placeholder={"Kode reCaptcha"} value={captcha} onChange={(e) => setCaptha(e.target.value)} />
            </div>
          </div>
          <div className="form-group mb-2 items-center">
            <div className="mt-1 -mb-2">
              <Link href={"/Pendaftaran/lupa_password"} >
                <a className=" text-white hover:text-white ">
                  <b>Forgot your password ?</b>
                </a>
              </Link>
            </div>
          </div>
          
          {/* <button type="submit" onClick={Login} disabled={login} */}
          <button type="submit" onClick={Login} disabled={login ? true : password != '' && username != '' ? false : true}
         
            className={`group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white ${disabled_style} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}>
            Login
          </button>
          
          <div className="text-sm text-center mt-3">
            <p className="font-light text-white">
              Dont have account ?
              <Link href={"/register"} >
                <a className=" text-white hover:text-white">
                  <b> Register</b>
                </a>
              </Link>
              <br />
            </p>
          </div>
        </div>
      </LoginLayouts>
    </>
  )
}
